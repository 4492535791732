import { gql } from "@apollo/client"
import AssociationList from "src/components/AssociationList"
import AssociationSearchSection from "src/components/AssociationSearchSection"
import Loader from "src/components/Loader"
import { useThemeFromDomainTheme } from "src/components/Theme"
import { DOMAIN_ID } from "src/constants"
import { useClientQuery } from "src/helpers/apollo"
import { DomainThemeField, SearchAssociationFields } from "src/helpers/fragments"
import { getParams } from "src/helpers/search"
import useInfiniteScroll from "src/hooks/infiniteScroll"
import Layout from "src/layout"
import { ErrorMessage, LoaderPlaceholder } from "src/pages/search"

const SEARCH_QUERY = gql`
  query($domainId: ID!, $text: String, $tagId: ID!, $page: Int!, $perPage: Int!) {
    allAssociations(domain: $domainId, q: $text, tags: [$tagId], type: Association, page: $page, perPage: $perPage) {
      ...SearchAssociationFields
    }
    meta: _allAssociationsMeta(domain: $domainId, q: $text, tags: [$tagId], type: Association) {
      count
    }
    allDomainSearchIntentTagCounts(domain: $domainId, tags: [$tagId]) {
      tag {
        id
        name
      }
    }
    Domain(id: $domainId) {
      outlink_url
      legal_notice_url
      facebook_url
      twitter_url
      instagram_url
      ...DomainThemeField
    }
  }
  ${SearchAssociationFields}
  ${DomainThemeField}
`
const SEARCH_QUERY_NOTAG = gql`
  query($domainId: ID!, $text: String, $page: Int!, $perPage: Int!) {
    allAssociations(domain: $domainId, q: $text, type: Association, page: $page, perPage: $perPage) {
      ...SearchAssociationFields
    }
    meta: _allAssociationsMeta(domain: $domainId, q: $text, type: Association) {
      count
    }
    allDomainSearchIntentTagCounts(domain: $domainId) {
      tag {
        id
        name
      }
    }
    Domain(id: $domainId) {
      legal_notice_url
      facebook_url
      twitter_url
      instagram_url
      ...DomainThemeField
    }
  }
  ${SearchAssociationFields}
  ${DomainThemeField}
`

interface Data {
  assolib: {
    allAssociations: [Association]
    allDomainTagCounts: [DomainTagCount]
    Domain: Domain
  }
}

const AssociationsPage: GatsbyPage<Data> = ({ location }) => {
  const [params] = getParams(location, ["q", "tag"])
  const text = params.q && params.q[0]
  const tagId = params.tag && params.tag[0]

  const variables = {
    text,
    tagId,
    domainId: DOMAIN_ID,
  }
  const { data, loading, error, fetchMore } = useClientQuery(
    tagId ? SEARCH_QUERY : SEARCH_QUERY_NOTAG,
    variables,
    "allAssociations"
  )
  const theme = useThemeFromDomainTheme(data?.Domain?.theme)
  const domain = data?.Domain

  useInfiniteScroll(fetchMore)

  if (!data && loading) {
    return null
  }

  return (
    <Layout theme={theme} domain={domain}>
      <AssociationSearchSection text={text} tagId={tagId} />
      {data && (
        <AssociationList
          hasFilters
          associations={data.allAssociations}
          tagCounts={data.allDomainSearchIntentTagCounts}
          tagId={tagId}
          loading={loading}
          wording="association"
        />
      )}
      {error && <ErrorMessage>Une erreur est survenue. Veuillez recharger la page.</ErrorMessage>}
      {!data && <LoaderPlaceholder>{loading && <Loader />}</LoaderPlaceholder>}
    </Layout>
  )
}

export default AssociationsPage
