import { useLocation } from "@reach/router"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useEffect } from "react"
import { Container, Content, FindButton } from "src/components/ActivityFiltersSection"
import SearchInput from "src/components/SearchInput"
import Select from "src/components/Select"
import { usePropsState } from "src/helpers/hooks"

const DATA_QUERY = graphql`
  query {
    assolib {
      allDomains {
        home_title
        home_subtitle
      }
      allTags(filter: { type: MAIN }) {
        id
        name
      }
    }
  }
`

const getSearchQuery = (query?: string, tag?: Tag) => {
  const params = []
  if (query) {
    params.push(`q=${encodeURIComponent(query)}`)
  }
  if (tag) {
    params.push(`tag=${tag.id}`)
  }
  return `?${params.join("&")}`
}

interface Props {
  text?: string
  tagId?: ID
}

interface Data {
  assolib: {
    allDomains: Domain[]
    allTags: Tag[]
  }
}

const AssociationSearchSection = ({ text, tagId }: Props = {}) => {
  const { assolib } = useStaticQuery<Data>(DATA_QUERY)
  const { search } = useLocation()

  const getInitialTag = () => tagId && assolib.allTags.find(({ id }) => id === tagId)

  const [query, setQuery] = usePropsState<string>(text || "", text)
  const [tag, setTag] = usePropsState<Tag>(getInitialTag, tagId)

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    navigate(getSearchQuery(query, tag))
  }

  useEffect(() => {
    if (tag !== undefined) {
      const searchQuery = getSearchQuery(query, tag)
      if (searchQuery !== search) {
        navigate(searchQuery)
      }
    }
  }, [tag]) // eslint-disable-line react-hooks/exhaustive-deps

  const domain = assolib.allDomains[0]

  return (
    <Container>
      <h1>{domain.home_title}</h1>
      <h2>{domain.home_subtitle}</h2>
      <Content as="form" onSubmit={handleSearch}>
        <SearchInput value={query} onChange={setQuery} />
        <Select value={tag} onChange={setTag} list={assolib.allTags} emptyOptionName="Tous les domaines" />
        <FindButton type="submit">Trouver</FindButton>
      </Content>
    </Container>
  )
}

export default AssociationSearchSection
