import { InputButton } from "src/components/ModalSelect"
import { Box } from "src/components/SearchInput"
import { Container, Dropdown, Option } from "src/components/TagAutocomplete"
import { capitalize } from "src/helpers/text"
import useClickAway from "src/hooks/clickAway"
import Chevron from "src/svg/chevron.svg"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const Icon = styled(Chevron)<{ $open: boolean }>`
  transform: rotate(${({ $open }) => ($open ? "-90deg" : "90deg")});
  fill: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  width: 14px;
  height: 19px;
`

interface Props {
  value?: Tag | null
  onChange: (value: Tag | null) => void
  list: Tag[]
  emptyOptionName?: string
}

const Select = ({ value, onChange, list, emptyOptionName }: Props) => {
  const { open, setOpen, ref } = useClickAway()

  const handleToggle = () => setOpen(!open)

  return (
    <Container ref={ref}>
      <Box>
        <InputButton type="button" onClick={handleToggle}>
          {value ? capitalize(value.name) : "Domaine d’activité"}
        </InputButton>
        <button onClick={handleToggle} aria-label="Voir la liste" type="button">
          <Icon $open={open} />
        </button>
      </Box>
      {open && (
        <Dropdown>
          {emptyOptionName && (
            <Option
              onClick={() => {
                onChange(null)
                setOpen(false)
              }}
            >
              {emptyOptionName}
            </Option>
          )}
          {list.map((tag) => (
            <Option
              key={tag.id}
              onClick={() => {
                onChange(tag)
                setOpen(false)
              }}
            >
              {capitalize(tag.name)}
            </Option>
          ))}
        </Dropdown>
      )}
    </Container>
  )
}

export default Select
